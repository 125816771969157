import Headroom from "react-headroom"

import styled, { css } from "../styled"
import { footNoteStyle } from "../Typography"

export const Logo = styled.h1`
  display: inline-flex;
  align-items: center;
`

export const Col = styled.div`
  flex: 1;
  display: flex;
  align-items: center;

  &:last-child {
    justify-content: flex-end;
  }
`

export const Header = styled.header`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 0.9em;
`

// Desktop
export const HeaderDesktop = styled.div`
  text-align: center;

  @media (max-width: 600px) {
    display: none;
  }
`

export const HeaderFixed = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: white;

  margin: 0 1.5em;
  padding: 1em 1.5em;
  transition: all ${({ theme: { transition } }) => transition.ease()};
  transform: translateY(-200%);

  ${({ show }) =>
    show &&
    css`
      transform: translateY(0%);
    `}
`

export const HeaderText = styled.div`
  ${footNoteStyle}
  color: ${({ theme: { colors } }) => colors.grayscale.default};

  a {
    color: ${({ theme: { colors } }) => colors.blue.default};
  }
`

// Mobile
export const HeaderMobile = styled.div`
  display: none;

  @media (max-width: 600px) {
    display: flex;
  }
`

export const HeaderHeadroom = styled(Headroom)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999999;
  height: 0px !important; // blank div, blocks button click

  .headroom {
    transition: all ${({ theme: { transition } }) => transition.ease()};

    background: white;
    margin: 0;
    padding: 1em 1.5em;
  }

  @media (max-width: 600px) {
    margin-top: 0;
  }

  .headroom--unfixed {
    transform: translateY(-100%) !important;
  }
`
