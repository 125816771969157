import * as React from "react"
import { Spring } from "react-spring"

import { ANIMATION_CONFIG } from "./constants"

interface IChildren {
  inverse?: boolean
  children: (props: { style: {}; elRef: any }) => JSX.Element | null
}

const globalWindow: any = typeof window !== "undefined" ? window : {}
const MIDDLE_VIEW = globalWindow!.innerHeight * 0.7

const AnimatedView: React.FC<IChildren> = ({ children, inverse }) => {
  const ref = React.useRef<any>(null)
  const [inView, setInView] = React.useState(false)

  const handleInView = () => {
    if (ref.current) {
      if (inverse) {
        setInView(ref.current.getBoundingClientRect().top > 0)
      } else {
        setInView(ref.current.getBoundingClientRect().top - MIDDLE_VIEW < 0)
      }
    }
  }

  React.useEffect(() => {
    globalWindow!.addEventListener("scroll", handleInView)

    return () => globalWindow!.removeEventListener("scroll", handleInView)
  }, [])

  return (
    <Spring
      config={ANIMATION_CONFIG}
      to={{
        opacity: inView ? 1 : 0,
        transform: `translateY(${inView ? 0 : 50}px)`,
      }}
    >
      {(props) => children({ style: props, elRef: ref })}
    </Spring>
  )
}

export default AnimatedView
