import { Link } from "gatsby"
import * as React from "react"

import arrowSrc from "../../images/arrow.svg"
import styled, { css } from "../styled"
import { textStyle } from "../Typography"

export interface IProp {
  arrow?: boolean
  children?: React.ReactNode
  color?: string
  ghost?: boolean
  to: string
  childrenProps?: { [key: string]: any }
  [key: string]: any
}

function applyHover() {
  return css`
    svg {
      transition: transform ${({ theme: { transition } }) => transition.ease()};
    }

    &:hover {
      svg {
        transform: translateX(0.2em);
      }
    }
  `
}

const LinkComp = styled(Link)<IProp>`
  ${props => !props.ghost && textStyle};
  ${props => !props.ghost && applyHover};
  font-size: 1em;
  color: ${({ theme }) => theme.colors.blue.default};
`

export const LinkExternal = styled.a<IProp>`
  ${props => !props.ghost && textStyle};
  ${props => !props.ghost && applyHover};
  font-size: 1em;
  color: ${({ theme }) => theme.colors.blue.default};
`

const LinkWrap: React.FC<IProp> = ({
  children,
  color,
  to,
  arrow,
  ...props
}) => {
  const isExternal = /mailto:|https?:\/\/((?:[\w\d-]+\.)+[\w\d]{2,})/i.test(to)
  const isEmail = /mailto:([^\?]*)/i.test(to)

  const childrenRender = (
    <div>
      <span>{children}</span>
      {arrow && <img src={arrowSrc} alt="arrow" />}
    </div>
  )

  if (isExternal) {
    return (
      <LinkExternal
        color={color}
        href={to}
        target={isEmail ? "" : "_blank"}
        rel="noreferrer"
        {...props}
      >
        {childrenRender}
      </LinkExternal>
    )
  }

  return (
    <LinkComp color={color} to={to} {...props}>
      {childrenRender}
    </LinkComp>
  )
}

export default LinkWrap
