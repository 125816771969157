import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import * as S from "./header.css"
import Link from "../UI/Link"
import Button from "../UI/Button"
import logo from "../../images/logo.svg"

interface IHeader {
  contentYaml: {
    header: {
      link: string
      linkText: string
      button: string
      buttonText: string
      left: string
    }
  }
}
const globalWindow: any = typeof window !== "undefined" ? window : {}
const WINDOW_HEIGHT = globalWindow.innerHeight

const Header = () => {
  const {
    contentYaml: { header },
  }: IHeader = useStaticQuery(query)

  const [shouldDesktopAppears, setShouldDesktopAppears] = React.useState(false)

  const handleDesktop = () => {
    setShouldDesktopAppears(window.scrollY >= WINDOW_HEIGHT)
  }

  React.useEffect(() => {
    window.addEventListener("scroll", handleDesktop)

    return () => window.removeEventListener("scroll", handleDesktop)
  }, [])

  return (
    <div>
      <S.HeaderMobile>
        <S.Logo>
          <img src={logo} alt="Akasha" />
        </S.Logo>

        <S.HeaderHeadroom>
          <S.Header>
            <S.Logo>
              <img src={logo} alt="Akasha" />
            </S.Logo>

            <S.Col>
              <Button to={header.button} ghost={true} newTab={true}>
                {header.buttonText}
              </Button>
            </S.Col>
          </S.Header>
        </S.HeaderHeadroom>

        <S.Col>
          <Button to={header.button} ghost={true} newTab={true}>
            {header.buttonText}
          </Button>
        </S.Col>
      </S.HeaderMobile>

      <S.HeaderDesktop>
        <S.HeaderFixed show={shouldDesktopAppears}>
          <S.Header>
            <S.Col>
              <S.HeaderText dangerouslySetInnerHTML={{ __html: header.left }} />
            </S.Col>

            <S.Logo>
              <img src={logo} alt="Akasha" />
            </S.Logo>

            <S.Col>
              <Button to={header.button} ghost={true} newTab={true}>
                {header.buttonText}
              </Button>
            </S.Col>
          </S.Header>
        </S.HeaderFixed>
        <S.Col>
          <Button to={header.button} ghost={true} newTab={true}>
            {header.buttonText}
          </Button>
        </S.Col>
      </S.HeaderDesktop>
    </div>
  )
}

export default Header

const query = graphql`
  query Header {
    contentYaml {
      header {
        link
        linkText
        button
        buttonText
        left
      }
    }
  }
`
