import * as React from "react"
import Image from "gatsby-image"

import * as S from "../timeline.css"
import * as T from "../../Typography"
import AnimatedView from "../../../utils/AnimatedView"
import { IProp } from "../templates"
import timelineAssets from "../timelineAssets"

const TextTemplate: React.FC<IProp> = React.forwardRef(
  ({ data, id, dark, future, style: itemStyle, ...rest }, ref) => {
    const asset = timelineAssets({ name: data.frontmatter.illustration })
    const position = data.frontmatter.illustrationOrder

    return (
      <AnimatedView inverse={future}>
        {({ style, elRef }) => {
          return (
            <S.Item id={id} ref={ref} style={{ ...style, ...itemStyle }}>
              {position === "front" && asset && (
                <S.Asset front>
                  <Image fluid={asset} />
                </S.Asset>
              )}

              <S.TextCard {...rest} dark={dark} style={style}>
                {position === "back" && asset && (
                  <S.Asset>
                    <Image fluid={asset} />
                  </S.Asset>
                )}
                <S.Circle ref={elRef} />
                <T.Tag>{data.frontmatter.shortDate}</T.Tag>
                <T.Caption>{data.frontmatter.title}</T.Caption>
                <T.Text as="div" className="content" color="grey">
                  <div dangerouslySetInnerHTML={{ __html: data.html }} />
                </T.Text>
              </S.TextCard>
            </S.Item>
          )
        }}
      </AnimatedView>
    )
  }
)

export default TextTemplate
