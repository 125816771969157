import { graphql, useStaticQuery } from "gatsby"
import * as React from "react"

import * as S from "./cookieDisclaimer.css"
import * as T from "../Typography"
import Link from "../UI/Link"
import Button from "../UI/Button"

export interface ICookie {
  contentYaml: { cookiePolicy: { title: string; cta: string; accept: string } }
}

const CookieDisclaimer = () => {
  const {
    contentYaml: { cookiePolicy },
  }: ICookie = useStaticQuery(query)
  const [accepted, setAccepted] = React.useState(true)

  React.useEffect(() => {
    const cookieData =
      typeof window !== "undefined"
        ? window.localStorage.getItem("akasha__cookiePolicy") === "true"
        : false

    setAccepted(cookieData)
  }, [])

  const handleCookie = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem("akasha__cookiePolicy", "true")
    }

    setAccepted(true)
  }

  return (
    <S.Wrapper accepted={accepted}>
      <T.Text>
        {cookiePolicy.title}{" "}
        <Link
          style={{ verticalAlign: "bottom" }}
          color="blue"
          to="https://akasha.org/privacy-policy/"
        >
          {cookiePolicy.cta}
        </Link>
      </T.Text>
      <Button onClick={handleCookie} ghost>
        {cookiePolicy.accept}
      </Button>
    </S.Wrapper>
  )
}

export default CookieDisclaimer

const query = graphql`
  query cookieDisclaimer {
    contentYaml {
      cookiePolicy {
        title
        cta
        accept
      }
    }
  }
`
