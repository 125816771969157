import styled, { createGlobalStyle } from "../styled"

export const GlobaStyle = createGlobalStyle`
  html {
    scroll-behavior: unset;
    background: #fff;
    transition: background .2s linear;
  }

  body {
    background: ${({ theme }) => theme.colors.grayscale.light};
    margin: 1.5em;
    border-radius: 4px;
    transition: background .2s linear;;

    @media (max-width: 600px) {
      margin: 0;
      border-radius: 0;
    }
  }
`

export const Wrapper = styled.main`
  padding: 2em 1.5em 1.5em;
  overflow: hidden;
`
