import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"

import getSocialIcons from "../../utils/getSocialIcons"
import * as S from "./footer.css"

interface IFooter {
  contentYaml: {
    footer: {
      left: string
    }
    homepage: {
      social: Array<{
        label: string
        src: string
      }>
    }
  }
}

const Footer = () => {
  const {
    contentYaml: { footer, homepage },
  }: IFooter = useStaticQuery(query)

  return (
    <S.Footer>
      <S.FooterText dangerouslySetInnerHTML={{ __html: footer.left }} />
      <div>
        {homepage.social.map(({ label, src }) => (
          <S.SocialLink key={src} href={src} target="_blank" rel="noreferrer">
            {getSocialIcons(label)}
          </S.SocialLink>
        ))}
      </div>
    </S.Footer>
  )
}

export default Footer

const query = graphql`
  query Footer {
    contentYaml {
      footer {
        left
      }
      homepage {
        social {
          label
          src
        }
      }
    }
  }
`
