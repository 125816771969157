import styled from "../styled"

import { footNoteStyle } from "../Typography"

export const Footer = styled.footer`
  padding-top: 20vh;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 600px) {
    display: block;
    text-align: center;

    > div {
      margin-top: 1em;
    }
  }
`

export const FooterText = styled.div`
  ${footNoteStyle}
  color: ${({ theme: { colors } }) => colors.grayscale.default};

  a {
    color: ${({ theme: { colors } }) => colors.blue.default};
  }
`

export const SocialLink = styled.a`
  color: ${({ theme: { colors } }) => colors.grayscale.default};
  margin: 0 0.3em;
  transition: opacity ${({ theme: { transition } }) => transition.ease()};

  svg {
    width: 20px;
    display: block;
  }

  &:hover {
    opacity: 0.8;
  }
`
