import styled, { css } from "../styled"
import { animated } from "react-spring"
import arrowSrc from "../../images/arrow.svg"
import playButton from "../../images/play-button.png"

const MARGIN = 32
const CARD_WIDTH = 360
const CIRCLE_SIZE = 8

export const Video = styled.div`
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  margin: -1.5em -1.5em 1.5em -1.5em;
  border-radius: 4px;

  iframe,
  object,
  embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

export const Asset = styled.div<{ front?: boolean }>`
  width: 100%;
  padding-top: 50%;

  position: absolute;
  top: 0;
  right: 0;
  left: 0;

  z-index: -1;

  > div {
    position: absolute !important;
    top: -100%;
    right: 0;
    left: -20%;
  }

  ${({ front }) =>
    front &&
    css`
      z-index: 1;
    `}
`

export const Circle = styled.div`
  width: ${CIRCLE_SIZE}px;
  height: ${CIRCLE_SIZE}px;
  border-radius: ${CIRCLE_SIZE}px;

  background: ${({ theme: { colors } }) => colors.blue.default};
  position: absolute;
  top: 0;
  right: calc(50% - ${CIRCLE_SIZE / 2}px);
  z-index: 2;

  @media (max-width: 600px) {
    left: -${CIRCLE_SIZE / 3}px;
  }
`

//
// Timeline items
//

// Text

export const Item = styled.div<{ ref: any; hasAsset?: boolean }>`
  position: relative;

  @media (max-width: 600px) {
    ${({ hasAsset }) =>
      hasAsset &&
      css`
        margin-top: 20vh !important;
      `}
  }
`

export const BaseCard = styled(animated.div)`
  width: 100%;
  position: relative;
  border: 1px solid transparent;
  will-change: auto;

  @media (min-width: 600px) {
    max-width: ${CARD_WIDTH}px;
  }

  h3 {
    margin-bottom: 0.5em;
  }

  .content a {
    font-size: 1em;
    color: ${({ theme }) => theme.colors.blue.default};
    margin-top: 1em;

    background-image: url(${arrowSrc});
    background-position: top 6px right 0.6em;
    background-repeat: no-repeat;
    padding-right: 2.4em;
    transition: all ${({ theme: { transition } }) => transition.ease()};

    &:hover {
      background-position: top 6px right 0.1em;
    }
  }
`

export const TextCard = styled(BaseCard)<{ dark?: boolean }>`
  ${({ dark }) =>
    dark &&
    css`
      h3 {
        color: #fff;
      }
    `}
`

// Card
export const HandleCard = styled(BaseCard)``

export const Card = styled.div<{ dark?: boolean }>`
  padding: 1.5em;
  box-shadow: ${({ theme: { shadow } }) => shadow.default};
  border: 1px solid rgba(83, 98, 124, 0.07);
  border-radius: 4px;
  background: ${({ theme: { colors } }) => colors.grayscale.white};

  ${({ dark }) =>
    dark &&
    css`
      background: #0f192c;

      h3 {
        color: #fff;
      }
    `}

  .video-card {
    position: relative;
    overflow: hidden;

    img {
      margin: -35px 0;
    }

    &:after {
      content: "";
      display: block;
      background: url(${playButton});
      background-size: cover;
      width: 70px;
      height: 50px;
      position: absolute;
      top: calc(50% - (70px / 2));
      left: calc(50% - (50px / 2));
    }
  }

  .video-card,
  .gatsby-image-wrapper {
    margin: -1.5em -1.5em 1.5em -1.5em;
  }
`

// Mosaic
export const Mosaic = styled(BaseCard)``

export const MosaicImage = styled.div`
  margin-bottom: 1em;
  border-radius: 4px;
  overflow: hidden;

  @media (min-width: 1050px) {
    margin-bottom: -3em;
    margin-right: -5em;
    margin-left: 5em;
  }
`

export const MosaicCard = styled.div<{ dark?: boolean }>`
  position: relative;
  padding: 1.5em;
  box-shadow: ${({ theme: { shadow } }) => shadow.default};
  border: 1px solid rgba(83, 98, 124, 0.07);
  border-radius: 4px;
  background: ${({ theme: { colors } }) => colors.grayscale.white};

  ${({ dark }) =>
    dark &&
    css`
      background: #0f192c;

      h3 {
        color: #fff;
      }
    `}
`

// Grid
export const Grid = styled.div<{ future?: boolean }>`
  width: 100%;
  max-width: calc(${CARD_WIDTH}px * 2 + ${MARGIN}px * 2);

  /* for mobile */
  @media (max-width: 600px) {
    padding-top: 0;

    ${({ future }) =>
      future &&
      css`
        padding-top: 0vh;
      `}

    > div {
      margin-top: 10em;
      padding-left: ${MARGIN}px;
    }

    ${Circle} {
      top: 3px;
      left: -${MARGIN + CIRCLE_SIZE / 2}px;
    }
  }

  /* for desktop */
  @media (min-width: 600px) {
    padding-top: 40vh;

    ${({ future }) =>
      future &&
      css`
        padding-top: 30vh;
      `}

    > div {
      width: calc(50% - ${MARGIN}px);
      max-width: ${CARD_WIDTH}px;
    }

    > *:nth-child(odd) {
      float: right;
      clear: right;

      > div {
        margin-top: 12em;
      }

      ${Circle} {
        top: 3px;
        left: -${MARGIN + 1 + CIRCLE_SIZE / 2}px;
        right: auto;
      }

      ${Mosaic} {
        @media (min-width: 1050px) {
          right: -5em;
        }

        ${Circle} {
          left: calc(-5em - ${MARGIN + 1 + CIRCLE_SIZE / 2}px);
        }
      }
    }

    > *:nth-child(even) {
      float: left;
      clear: left;

      > div {
        margin-top: 7em;
      }

      ${Circle} {
        top: 3px;
        right: -${MARGIN + 1 + CIRCLE_SIZE / 2}px;
      }

      ${Mosaic} {
        @media (min-width: 1050px) {
          left: -5em;
        }

        ${Circle} {
          right: calc(-5em + -${MARGIN + 1 + CIRCLE_SIZE / 2}px);
        }

        ${MosaicImage} {
          @media (min-width: 1050px) {
            margin-left: 5em;
            margin-right: -5em;
          }
        }
      }
    }

    /* Actually it is the first item */
    > *:nth-child(2) {
      margin-top: -10em;
    }

    > *:last-child {
      clear: both;
    }
  }
`
