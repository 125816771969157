import { graphql, useStaticQuery } from "gatsby"
import { FluidObject } from "gatsby-image"

interface IProp {
  name: string
}

interface IImages {
  allFile: {
    edges: Array<{
      node: {
        name: string
        childImageSharp: {
          fluid: FluidObject
        }
      }
    }>
  }
}

const timeLineAssets = ({ name }: IProp) => {
  const { allFile }: IImages = useStaticQuery(query)

  if (!name) {
    return null
  }

  const asset = allFile.edges.find(({ node }) => node.name === name)

  return asset ? asset.node.childImageSharp.fluid : null
}

export default timeLineAssets

const query = graphql`
  query allIllustrations {
    allFile(filter: { relativeDirectory: { eq: "illustrations" } }) {
      edges {
        node {
          name
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        }
      }
    }
  }
`
