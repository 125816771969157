import * as React from "react"
import "wipe.css"

import { Typography } from "../Typography"
import { ThemeProvider } from "../styled"
import theme from "./theme"

const Provider: React.FC = ({ children }) => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Typography />
        {children}
      </>
    </ThemeProvider>
  )
}

export default Provider
