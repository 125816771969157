import * as React from "react"

import Provider from "../Provider"
import Header from "../Header/header"
import Footer from "../Footer/footer"
import CookieDisclaimer from "../CookieDisclaimer/cookieDisclaimer"

import * as S from "./layout.css"

const Layout: React.FC = ({ children }) => (
  <Provider>
    <CookieDisclaimer />
    <S.GlobaStyle />
    <S.Wrapper>
      <Header />
      {children}
      <Footer />
    </S.Wrapper>
  </Provider>
)

export default Layout
