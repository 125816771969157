export default {
  colors: {
    blue: {
      dark: "#000C20",
      default: "#0070e8",
      light: "#EBF5FF",
      middle: "#0069D9",
    },
    grayscale: {
      black: "#000",
      dark: "#2E3747",
      default: "#8A94A6",
      medium: "#53627C",
      mediumLight: "#EDEFF1",
      light: "#FAFAFA",
      white: "#fff",
    },
    orange: {
      default: "#F7A736",
    },
    red: {
      default: "#EE3465",
    },
  },
  shadow: {
    default: "0 2px 12px 0 rgba(0, 0, 0, 0.03)",
    secondary: "0 3px 10px 0 rgba(0, 0, 0, 0.2)",
  },
  transition: {
    cubic: (ms = 300) => `cubic-bezier(0, 1.2, 1, 1) ${ms}ms`,
    ease: (ms = 200) => `${ms}ms ease-in-out`,
  },
}
