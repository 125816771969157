import * as React from "react"

import Akasha from "../images/social/akasha"
import Discord from "../images/social/discord"
import Dribbble from "../images/social/dribbble"
import Facebook from "../images/social/facebook"
import Github from "../images/social/github"
import Keybase from "../images/social/keybase"
import Linkedin from "../images/social/linkedin"
import Medium from "../images/social/medium"
import Reddit from "../images/social/reddit"
import Twitter from "../images/social/twitter"
import Website from "../images/social/website"
import Youtube from "../images/social/youtube"

export default (icon: string) => {
  const icons: any = {
    akasha: <Akasha />,
    discord: <Discord />,
    dribbble: <Dribbble />,
    facebook: <Facebook />,
    github: <Github />,
    keybase: <Keybase />,
    linkedin: <Linkedin />,
    medium: <Medium />,
    reddit: <Reddit />,
    twitter: <Twitter />,
    youtube: <Youtube />,
  }

  return icons[icon] ? icons[icon] : <Website />
}
