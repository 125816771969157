// themed-styled-component.ts
// tslint:disable:no-duplicate-imports
import * as styledComponents from "styled-components"
import { ThemedStyledComponentsModule } from "styled-components"
import { IThemeInterface } from "./Provider/types"

const {
  default: styled,
  css,
  createGlobalStyle,
  ThemeProvider,
  ThemeConsumer,
  keyframes,
} = styledComponents as any as ThemedStyledComponentsModule<IThemeInterface>

export { css, createGlobalStyle, keyframes, ThemeProvider, ThemeConsumer }
export default styled
