import styled, { css } from "../styled"

export const Wrapper = styled.div<{ accepted: boolean }>`
  ${({ theme: { transition, colors }, accepted }) => css`
    position: fixed;
    bottom: 1em;
    left: 0;
    right: 0;
    z-index: 9999;
    max-width: 70em;

    padding: 1em;
    margin: 0 auto;
    display: flex;

    background: ${colors.grayscale.mediumLight};
    border-radius: 4px;
    transition: all ${transition.cubic()};
    transform: translateY(${accepted ? "150%" : 0});
    opacity: ${accepted ? 0 : 1};

    @media (max-width: 600px) {
      display: block;
      bottom: 0;
      font-size: 12px;
    }

    button {
      white-space: nowrap;
      min-width: 110px;
      margin-left: 2mm;

      @media (max-width: 600px) {
        margin-top: 1em;
        margin-left: 0;
      }
    }
  `}
`
