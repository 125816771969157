import styled, { createGlobalStyle, css } from "../styled"
import { IThemeInterface } from "../Provider/types"

const fontface = require("./fonts/index.css")

export interface IProp {
  color?: string
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "initial"
    | "inherit"
}

export interface IAplyColor {
  theme: IThemeInterface
  color?: string
}

function applyColor(props: IAplyColor): string {
  const { colors } = props.theme

  const map: any = {
    black: colors.grayscale.black,
    blue: colors.blue.default,
    blueDark: colors.blue.dark,
    default: colors.grayscale.black,
    grey: colors.grayscale.default,
    greyDark: colors.grayscale.dark,
    greyLight: colors.grayscale.light,
    greyMedium: colors.grayscale.medium,
    orange: colors.orange.default,
    red: colors.red.default,
    white: colors.grayscale.white,
  }

  if (props.color !== undefined) {
    return map[props.color]
  }

  return map.default
}

// **
// Base
// **
export const Typography = createGlobalStyle`
  ${fontface}
  
  body, input, button, select {
    font-family: 'AvertaStd', sans-serif;
  }
`

export const base = css`
  font-weight: normal;
  color: ${applyColor};
`

export const baseTitles = css`
  ${base};
  font-weight: 700;
`

// **
// Main Title
// **
export const mainTitleStyle = css`
  ${baseTitles};
  font-size: 2.5rem;
  line-height: 1.075;
  letter-spacing: -0.4px;
`

export const MainTitle = styled.h1`
  ${mainTitleStyle};
`

// **
// Title
// **
export const titleStyle = css`
  ${baseTitles};
  font-size: 1.75rem;
  line-height: 1.25;
`

export const Title = styled.h1`
  ${titleStyle};
`

// **
// SubTitle
// **
export const subTitleStyle = css`
  ${baseTitles};
  font-size: 1.5rem;
  line-height: 1.25;
`

export const SubTitle = styled.h2`
  ${subTitleStyle};
`

// **
// Caption
// **
export const captionStyle = css`
  ${base};
  font-weight: 500;
  font-size: 1.35rem;
  line-height: 1.35;
`

export const Caption = styled.h3`
  ${captionStyle};
`

// **
// Description
// **
export const descriptionStyle = css`
  ${base};
  font-size: 1.25rem;
  line-height: 1.35;
`

export const Description = styled.p`
  ${descriptionStyle};
`

// **
// BlogText
// **
export const blogTextStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.5;
`

export const BlogText = styled.p`
  ${blogTextStyle};
`

// **
// TextLink
// **
export const textLinkStyle = css`
  ${base};
  font-size: 1.125rem;
  line-height: 1.3;
  font-weight: 500;
`

export const TextLink = styled.p`
  ${textLinkStyle};
`

// **
// Text
// **
export const textStyle = css`
  ${base};
  font-size: 1rem;
  line-height: 1.4;
`

export const Text = styled.p`
  ${textStyle};
`

// **
// TextBold
// **
export const textStyleBold = css`
  ${base};
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.4;
`

export const TextBold = styled.p`
  ${textStyleBold};
`

// **
// FootNote
// **
export const footNoteStyle = css`
  ${base};
  font-size: 0.65rem;
  line-height: 1.5;
  text-transform: uppercase;
`

export const FootNote = styled.p`
  ${footNoteStyle};
`

// **
// Tag
// **
export const tagStyle = css`
  ${base};
  background: rgba(0, 123, 255, 0.1);
  border-radius: 2px;
  color: ${({ theme: { colors } }) => colors.blue.default};
  display: inline-block;
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 1rem;
  padding: 0.5em 0.5em 0.3em;
  text-transform: uppercase;
`

export const Tag = styled.p`
  ${tagStyle};
`
