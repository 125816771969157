const globalWindow: any = typeof window !== "undefined" ? window : {}
const currentUrl = globalWindow.location ? globalWindow.location.href : ""

export const endpoint =
  process.env.NODE_ENV !== "production"
    ? "http://localhost:9000"
    : ''

export const URL = `${endpoint}/api/newsletter`

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i

export const ANIMATION_CONFIG = { tension: 120, friction: 17 }

export const ANIMATION_DELAY = 600
